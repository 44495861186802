import invert from 'lodash/invert'
import { IClientInfo } from './client'
import { IFile } from './box'
import { IEntityInfo } from './entityInfo'
import { IRequiredReport } from './requiredReport'
import { Feedback, IFlagFeedback, IOPSReporting } from './prospects'
import { INote } from './notes'
import { IFee } from './loanServicing'
import { CalendarPickerView } from '@mui/x-date-pickers/CalendarPicker'

export enum FundingRequestStatus {
  Requested = 'REQUESTED',
  InProgress = 'IN PROGRESS',
  Review = 'READY FOR REVIEW',
  Completed = 'COMPLETED',
  Sent = 'SENT',
}

export enum FundingRequestAmountType {
  Specific = 'Specific',
  Max = 'Max',
  TBD = 'TBD',
}

export enum ClientInventoryTables {
  Historical = 'historical_client_inventory',
  Current = 'client_inventory',
}

export enum IneligibleReasons {
  SKUsAndSeason = 'Ineligible SKUs and Season',
  Locations = 'Ineligible Locations and In-Transit',
  SlowMoving = 'Slow Moving, Aged, Near/Past Expiration',
  OtherMiscReserves = 'Other Misc. Reserves',
}

export const INELIGIBLE_REASONS_LIST = Object.values(IneligibleReasons).map((value) => ({
  value,
  label: value,
}))

export const IneligibleReasonsMap = {
  sku: IneligibleReasons.SKUsAndSeason,
  type: IneligibleReasons.SKUsAndSeason,
  season: IneligibleReasons.SKUsAndSeason,
  size: IneligibleReasons.SKUsAndSeason,
  flavor: IneligibleReasons.SKUsAndSeason,
  style: IneligibleReasons.SKUsAndSeason,
  color: IneligibleReasons.SKUsAndSeason,
  brandCategory: IneligibleReasons.SKUsAndSeason,
  industrySubcategory: IneligibleReasons.SKUsAndSeason,
  location: IneligibleReasons.Locations,
  daysSinceLanded: IneligibleReasons.SlowMoving,
  daysUntilExpiration: IneligibleReasons.SlowMoving,
  sales: IneligibleReasons.SlowMoving,
  weeksOfSupply: IneligibleReasons.SlowMoving,
  lotNumber: IneligibleReasons.OtherMiscReserves,
  onHand: IneligibleReasons.OtherMiscReserves,
  avgUnitCost: IneligibleReasons.OtherMiscReserves,
  totalValue: IneligibleReasons.OtherMiscReserves,
  landedDate: IneligibleReasons.OtherMiscReserves,
  expirationDate: IneligibleReasons.OtherMiscReserves,
  salesUnits: IneligibleReasons.OtherMiscReserves,
  custom_1: IneligibleReasons.OtherMiscReserves,
  custom_2: IneligibleReasons.OtherMiscReserves,
  custom_3: IneligibleReasons.OtherMiscReserves,
  custom_4: IneligibleReasons.OtherMiscReserves,
  custom_5: IneligibleReasons.OtherMiscReserves,
}

export const FUNDING_REQUEST_AMOUNT_TYPE_LABEL = {
  [FundingRequestAmountType.Specific]: 'Specific',
  [FundingRequestAmountType.Max]: 'Max Amount',
  [FundingRequestAmountType.TBD]: 'TBD',
}

export enum OngoingReportingStatus {
  NotStarted = 'Not Started',
  Uploaded = 'Uploaded',
  Process = 'Process',
  InReview = 'In Review',
  Verified = 'Verified',
  Archived = 'Archived',
}

export const REPORTING_STATUS_LABEL = {
  [OngoingReportingStatus.NotStarted]: 'Not Started',
  [OngoingReportingStatus.Process]: 'In Progress',
  [OngoingReportingStatus.InReview]: 'In Review',
  [OngoingReportingStatus.Verified]: 'Complete',
  [OngoingReportingStatus.Uploaded]: 'Uploaded',
  [OngoingReportingStatus.Archived]: 'Archived',
}

export enum OngoingReportingType {
  BalanceSheet = 'BalanceSheet',
  IncomeStatement = 'IncomeStatement',
  IncomeStatementProjections = 'IncomeStatementProjections',
  BalanceSheetProjections = 'BalanceSheetProjections',
  ARGeneralLedger = 'ARGeneralLedger',
  SalesBySKU = 'SalesBySKU',
  AR = 'ARAging',
  AP = 'APAging',
  Inventory = 'InventoryDetail',
  BankTransactions = 'BankTransactions',
  CapTable = 'CapTable',
  OrgChart = 'OrgChart',
  Summary = 'Summary',
  Miscellaneous = 'Miscellaneous',
  Uncategorized = 'Uncategorized',
}

export const ONGOING_REPORTING_TYPE_LABEL = {
  [OngoingReportingType.BalanceSheet]: 'Balance Sheet',
  [OngoingReportingType.IncomeStatement]: 'Income Statement',
  [OngoingReportingType.IncomeStatementProjections]: 'Income Statement (Projections)',
  [OngoingReportingType.BalanceSheetProjections]: 'Balance Sheet (Projections)',
  [OngoingReportingType.ARGeneralLedger]: 'AR General Ledger',
  [OngoingReportingType.SalesBySKU]: 'Sales by SKU',
  [OngoingReportingType.AR]: 'AR Aging',
  [OngoingReportingType.AP]: 'AP Aging',
  [OngoingReportingType.Inventory]: 'Inventory Detail',
  [OngoingReportingType.BankTransactions]: 'Bank Transactions',
  [OngoingReportingType.CapTable]: 'Cap Table',
  [OngoingReportingType.OrgChart]: 'Org Chart',
  [OngoingReportingType.Summary]: 'Summary',
}

export enum TemplateTypes {
  ARDetail = 'arRaw',
  ARSummary = 'arRawSummary',
  APDetail = 'apRaw',
  APSummary = 'apRawSummary',
  InventoryDetail = 'inventory',
  IncomeStatement = 'incomeStatement',
  BalanceSheet = 'balanceSheet',
  ARGeneralLedger = 'arGeneralLedger',
  SalesBySKU = 'salesBySKU',
  SalesBySKUMonthlyHeader = 'salesBySKUMonthlyHeader',
}

export enum ReportingPeriods {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually',
}

export enum NonReportingPeriods {
  Daily = 'Daily',
}

export const CALENDAR_VIEWS_MAP: Record<
  ReportingPeriods | NonReportingPeriods,
  CalendarPickerView[]
> = {
  [ReportingPeriods.Annually]: ['year'],
  [NonReportingPeriods.Daily]: ['day'],
  [ReportingPeriods.Monthly]: ['month', 'year'],
  [ReportingPeriods.Quarterly]: ['month', 'year'],
}

export enum AnnuallyReportingPeriods {
  YTD = 'YTD',
  FY = 'FY',
}

export enum FinancialRowTypes {
  Header = 'Header',
  Total = 'Total',
  SubTotal = 'SubTotal',
  Normal = 'Normal',
}

export enum DisplayGrowthPeriods {
  Hide = 'Hide',
  Month = 'Month-over-month',
  Quarter = 'Quarter-over-quarter',
  Year = 'Year-over-year',
  ActualsProjections = 'Actuals/Projections',
  StressBase = 'Stress/Base',
}

export const REPORTING_PERIOD_OPTIONS = Object.values(ReportingPeriods).map((value) => ({
  value,
  label: value,
}))

export const ANNUALLY_REPORTING_PERIOD_OPTIONS = Object.values(AnnuallyReportingPeriods).map(
  (value) => ({
    value,
    label: value,
  }),
)

export const FILE_TYPE_TEMPLATES = {
  [OngoingReportingType.AR]: [
    {
      label: 'AR Detail',
      value: TemplateTypes.ARDetail,
    },
    {
      label: 'AR Summary',
      value: TemplateTypes.ARSummary,
    },
  ],
  [OngoingReportingType.AP]: [
    {
      label: 'AP Detail',
      value: TemplateTypes.APDetail,
    },
    {
      label: 'AP Summary',
      value: TemplateTypes.APSummary,
    },
  ],
  [OngoingReportingType.Inventory]: [
    {
      label: 'Inventory Detail',
      value: TemplateTypes.InventoryDetail,
    },
  ],
  [OngoingReportingType.IncomeStatement]: [
    {
      label: 'Income Statement',
      value: TemplateTypes.IncomeStatement,
    },
  ],
  [OngoingReportingType.BalanceSheet]: [
    {
      label: 'Balance Sheet',
      value: TemplateTypes.BalanceSheet,
    },
  ],
  [OngoingReportingType.IncomeStatementProjections]: [
    {
      label: 'Income Statement Projections',
      value: TemplateTypes.IncomeStatement,
    },
  ],
  [OngoingReportingType.BalanceSheetProjections]: [
    {
      label: 'Balance Sheet Projections',
      value: TemplateTypes.BalanceSheet,
    },
  ],
  [OngoingReportingType.ARGeneralLedger]: [
    {
      label: 'AR General Ledger',
      value: TemplateTypes.ARGeneralLedger,
    },
  ],
  [OngoingReportingType.SalesBySKU]: [
    {
      label: 'Sales by SKU',
      value: TemplateTypes.SalesBySKU,
    },
    {
      label: 'Sales by SKU (Monthly Header)',
      value: TemplateTypes.SalesBySKUMonthlyHeader,
    },
  ],
}

export const ONGOING_REPORTING_STATEMENT_PROCESSING_TYPE = {
  [OngoingReportingType.BalanceSheet]: 'Balance Sheet',
  [OngoingReportingType.IncomeStatement]: 'Income Statement',
  [OngoingReportingType.IncomeStatementProjections]: 'Income Statement',
  [OngoingReportingType.BalanceSheetProjections]: 'Balance Sheet',
}

export interface IOngoingReportingFinancialDates {
  [OngoingReportingType.IncomeStatement]?: {
    startDate?: string
    endDate?: string
  }
  [OngoingReportingType.BalanceSheet]?: {
    startDate?: string
    endDate?: string
  }
}

export interface IOngoingReportingFileSheet {
  id: string
  ongoingReportingId: string
  type: string
  fileId: string
  file?: IFile
  sheetName: string
  data: any
}

export interface IOngoingReporting {
  id: string
  type: OngoingReportingType
  status: OngoingReportingStatus
  recordDate: string
  dueDate: string
  createdAt: string
  updatedAt: string
  completedAt: string | null
  submittedDate?: string
  clientName: string
  clientInfo?: IClientInfo
  clientIconUrl?: string
  clientId?: string
  fileSheets?: IOngoingReportingFileSheet[]
  files?: IFile[]
  boxLink?: string
  isSubmitted: boolean
  unMappedDebtorsCount?: number
  unMappedTypeCount?: number
  daysSinceDue?: number
  statusLabel?: string
  asOfDate?: string
  preProcessed: boolean
  financialDates: IOngoingReportingFinancialDates
}

export interface IOngoingReportingData {
  data: IOngoingReporting[]
  totals: {
    totalItems: number
    reportsSubmittedToday: number
    reportsCompletedToday: number
    reportsPending: number
  }
}

export interface IOngoingReportingRawMapping {
  id: string
  clientName: string
  clientInfo?: IClientInfo
  income: string
  field: string | null
  prediction: string | null
  tags: { tag: string }[]
  statementType: OngoingReportingType
  values: { period: string; amount: number }[]
  amounts?: number[]
  depth?: number
  rowType?: FinancialRowTypes
}

export interface IOngoingReportingRawMappingData {
  data: IOngoingReportingRawMapping[]
  dictionary: {
    headers: string[]
    headersByStatementType: {
      [OngoingReportingType.IncomeStatement]: string[]
      [OngoingReportingType.BalanceSheet]: string[]
    }
    fields: string[]
    usedFields: string[]
  }
  totals: {
    totalItems: number
    totalMapped: number
    totalFields: number
  }
}

export interface IOngoingReportingSummaryData extends Record<FinancialKeys, number[]> {}

export interface IOngoingReportingSummaryHeaders {
  [OngoingReportingType.IncomeStatement]: string[]
  [OngoingReportingType.BalanceSheet]: string[]
}

export interface IOngoingReportingUpdatesItem {
  field: string
  recordDate: string
  oldValue: number
  newValue: number
}

export interface IncomeStatement {
  gross_revenue: number
  trade_discounts: number
  returns_refunds: number
  net_revenue: number
  dilution: number
  gross_margin: number
  product_cogs: number
  cost_of_goods_sold: number
  gross_profit: number
  total_operating_expenses: number
  payroll: number
  sales_and_marketing: number
  research_and_development: number
  other_operating_expenses: number
  operating_income: number
  interest_expense: number
  depreciation_and_amortization: number
  taxes: number
  other_expenses: number
  other_income: number
  net_income: number
  ebitda: number
  ebitda_margin: number
  collections?: number
}

export interface BalanceSheet {
  cash_and_cash_equivalents: number
  accounts_receivable_net: number
  inventory: number
  prepaid_expenses: number
  other_current_assets: number
  total_current_assets: number
  net_ppe: number
  intangible_goodwill: number
  other_long_term_assets: number
  total_long_term_assets: number
  total_assets: number
  accounts_payable: number
  accrued_expenses: number
  taxes_payable: number
  deferred_revenue_and_deposits: number
  dwight_funding_loan: number
  credit_card_debt: number
  gift_cards: number
  other_short_term_debt: number
  other_current_liabilities: number
  total_current_liabilities: number
  convertible_notes_debt: number
  ppp: number
  eidl: number
  other_long_term_debt: number
  other_long_term_liabilities: number
  total_long_term_liabilities: number
  total_liabilities: number
  common_and_treasury_stock: number
  additional_paid_in_capital: number
  convertible_notes_equity: number
  retained_earnings: number
  other_shareholders_equity: number
  total_shareholders_equity: number
  quick_ratio: number
  current_ratio: number
}

interface FinancialReconcileSummary {
  assets: string
  cashflow: string
}

interface CashFlowSummary {
  change_in_cash: number
  change_in_cash_on_bs: number
}

export type BalanceSheetKeys = keyof BalanceSheet
export type IncomeStatementKeys = keyof IncomeStatement
type CashFlowSummaryKeys = keyof CashFlowSummary
type FinancialReconcileSummaryKeys = keyof FinancialReconcileSummary
export type FinancialKeys = BalanceSheetKeys | IncomeStatementKeys | CashFlowSummaryKeys
type FinancialMappingKeys = FinancialKeys | FinancialReconcileSummaryKeys

export const CLIENT_FINANCIALS_FIELDS_MAPPING: Partial<Record<FinancialMappingKeys, string>> = {
  gross_revenue: 'Gross Revenue',
  trade_discounts: 'Trade Discounts',
  returns_refunds: 'Returns Refunds',
  net_revenue: 'Net Revenue',
  dilution: 'Dilution',
  product_cogs: 'Product COGS',
  cost_of_goods_sold: 'Cost of Goods Sold',
  gross_profit: 'Gross Profit',
  total_operating_expenses: 'Total Operating Expenses',
  payroll: 'Payroll',
  sales_and_marketing: 'Sales & Marketing',
  research_and_development: 'Research & Development',
  other_operating_expenses: 'Other OpEx',
  operating_income: 'Operating Income',
  interest_expense: 'Interest Expense',
  depreciation_and_amortization: 'Depreciation & Amortization',
  taxes: 'Taxes',
  other_expenses: 'Other Expenses',
  other_income: 'Other Income',
  net_income: 'Net Income',
  cash_and_cash_equivalents: 'Cash & Cash Equivalents',
  accounts_receivable_net: 'Accounts Receivable Net',
  inventory: 'Inventory',
  total_current_assets: 'Total Current Assets',
  other_current_assets: 'Other Current Assets',
  net_ppe: 'Net Ppe',
  other_long_term_assets: 'Other Long Term Assets',
  total_long_term_assets: 'Total Long Term Assets',
  total_assets: 'Total Assets',
  assets: 'A=L+SE',
  cashflow: 'Cashflow',
  accounts_payable: 'Accounts Payable',
  other_current_liabilities: 'Other Current Liabilities',
  total_current_liabilities: 'Total Current Liabilities',
  other_long_term_liabilities: 'Other Long Term Liabilities',
  total_long_term_liabilities: 'Total Long Term Liabilities',
  total_liabilities: 'Total Liabilities',
  accrued_expenses: 'Accrued Expenses',
  deferred_revenue_and_deposits: 'Deferred Revenue & Deposits',
  dwight_funding_loan: 'Dwight Funding Loan',
  credit_card_debt: 'Credit Card Debt',
  other_short_term_debt: 'Other Short Term Debt',
  other_long_term_debt: 'Other Long Term Debt',
  total_shareholders_equity: 'Total Shareholders Equity',
  other_shareholders_equity: 'Other Shareholders Equity',
  convertible_notes_debt: 'Convertible Notes Debt',
  common_and_treasury_stock: 'Common & Treasury Stock',
  additional_paid_in_capital: 'Additional Paid in Capital',
  convertible_notes_equity: 'Convertible Notes Equity',
  retained_earnings: 'Retained Earnings',
  taxes_payable: 'Taxes Payable',
  gift_cards: 'Gift Cards',
  intangible_goodwill: 'Intangible Goodwill',
  prepaid_expenses: 'Prepaid Expenses',
  ppp: 'PPP',
  eidl: 'EIDL',
  ebitda: 'EBITDA',
  gross_margin: 'Gross Margin',
  ebitda_margin: 'EBITDA Margin',
  quick_ratio: 'Quick Ratio',
  current_ratio: 'Current Ratio',
  collections: 'Collections',
}

export const CLIENT_INCOME_STATEMENT_TAGS_MAPPING = {
  gross_revenue_dtc: 'Gross Rev - DTC',
  gross_revenue_wholesale: 'Gross Rev - Wholesale',
  gross_revenue_general: 'Gross Rev - General',
  gross_revenue_shipping_income: 'Gross Rev - Shipping Income',

  tradespend_dtc_discounts: 'TS - DTC Discounts',
  tradespend_dtc_returns_refunds: 'TS - DTC Returns & Refunds',
  tradespend_wholesale_discounts: 'TS - Wholesale Discounts',
  tradespend_wholesale_returns_refunds: 'TS - Wholesale Returns & Refunds',
  tradespend_omni_discount: 'TS - Omni Discount',
  tradespend_omni_returns_refunds: 'TS - Omni Returns & Refunds',

  net_revenue_dtc: 'Net Rev - DTC',
  net_revenue_wholesale: 'Net Rev - Wholesale',
  net_revenue_general: 'Net Rev - General',

  cogs_dtc: 'Channel COGS - DTC',
  cogs_wholesale: 'Channel COGS - Wholesale',
  cogs_general: 'Channel COGS - General',

  cogs_product: 'Comp. COGS - Product COGS',
  cogs_packaging: 'Comp. COGS - Packaging/Raw Materials',
  cogs_freight: 'Comp. COGS - Freight-In',
  cogs_inventory: 'Comp. COGS - Inventory Adjustment',
  cogs_duty: 'Comp. COGS - Duty',
  cogs_direct_labor: 'Comp. COGS - Direct Labor',

  selling_expense_warehouse_and_fulfillment: 'SE - Warehouse/Fulfillment/3PL',
  selling_expense_merchant_fees: 'SE - Merchant Fees',
  selling_expense_freight_out: 'SE - Freight-Out',
  selling_expense_general: 'SE - General',

  employee_costs_payroll: 'Employee Costs - Payroll',
  employee_costs_commision_payments: 'Employee Costs - Commission Payments',
  employee_costs_bonus: 'Employee Costs - Bonus',
  employee_costs_te: 'Employee Costs - T&E',
  employee_costs_general: 'Employee Costs - General',

  professional_fees_legal: 'Prof. Fees - Legal',
  professional_fees_accounting_finance: 'Prof. Fees - Accounting & Finance',
  professional_fees_recruiting: 'Prof. Fees - Recruiting',
  professional_fees_general: 'Prof. Fees - General',

  marketing_pr: 'Marketing - Public Relations',
  marketing_online_social: 'Marketing - Online/Social',
  marketing_print: 'Marketing - Print',
  marketing_influencer: 'Marketing - Influencer',
  marketing_photography_and_models: 'Marketing - Photography & Models',
  marketing_agencies: 'Marketing - Agencies',
  marketing_events: 'Marketing - Events',
  marketing_samples: 'Marketing - Samples',
  marketing_general: 'Marketing - General',

  platform_facebook: 'Platform - Facebook',
  platform_instagram: 'Platform - Instagram',
  platform_google: 'Platform - Google',
  platform_tiktok: 'Platform - TikTok',
  platform_bing: 'Platform - Bing',
  platform_amazon: 'Platform - Amazon',
  platform_youtube: 'Platform - YouTube',
  platform_twitter: 'Platform - Twitter',

  occupancy_rent: 'Occupancy - Rent',
  occupancy_repairs_and_maintenance: 'Occupancy - Repairs & Maintenance',
  occupancy_office_supplies: 'Occupancy - Office Supplies',
  occupancy_general: 'Occupancy - General',

  technology_hardware: 'Technology - Hardware',
  technology_software: 'Technology - Software',
  technology_website: 'Technology - Website',
  technology_general: 'Technology - General',

  misc_expense_insurance: 'Misc. Expense - Insurance',
  misc_expense_charitable_contributions: 'Misc. Expense - Charitable Contributions',
  misc_expense_dues_and_subscriptions: 'Misc. Expense - Dues & Subscriptions',
  misc_expense_general: 'Misc. Expense - General',
}

export const QUARTERS_MAPPING = {
  Q1: [0, 1, 2],
  Q2: [3, 4, 5],
  Q3: [6, 7, 8],
  Q4: [9, 10, 11],
}

export const CLIENT_BALANCE_SHEET_TAGS_MAPPING = {
  inventory_finished_goods: 'Inventory - Finished Goods',
  inventory_wip: 'Inventory - WIP',
  inventory_packaging: 'Inventory - Packaging',
  inventory_raw_materials: 'Inventory - Raw Materials',
  inventory_freight: 'Inventory - Freight',
  inventory_direct_labor: 'Inventory - Direct Labor',
  inventory_in_transit: 'Inventory - In Transit',
  inventory_other: 'Inventory - Other',
  inventory_prepaid_inventory: 'Inventory - Prepaid Inventory',
  inventory_general: 'Inventory - General',
  inventory_reserves_obsolescence: 'Inventory - Reserves/Obsolescence',

  apic_seed: 'APIC - Seed',
  apic_series_a: 'APIC - Series A',
  apic_series_b: 'APIC - Series B',
  apic_series_c: 'APIC - Series C',
  apic_series_d: 'APIC - Series D',
  apic_series_e: 'APIC - Series E',
  apic_series_f: 'APIC - Series F',
  apic_general: 'APIC - General',
}

export const CLIENT_FINANCIALS_HEADER_FIELDS: FinancialKeys[] = [
  'gross_revenue',
  'net_revenue',
  'gross_profit',
  'total_operating_expenses',
  'operating_income',
  'net_income',
  'total_current_assets',
  'total_long_term_assets',
  'total_assets',
  'total_current_liabilities',
  'total_liabilities',
  'total_shareholders_equity',
  'gross_margin',
  'dilution',
  'ebitda_margin',
  'quick_ratio',
  'current_ratio',
]
export const CLIENT_FINANCIALS_HEADER_FIELDS_WITH_EXPAND: FinancialKeys[] = [
  'gross_revenue',
  'dilution',
  'gross_margin',
  'operating_income',
  'total_current_assets',
  'total_assets',
  'total_current_liabilities',
  'total_liabilities',
]
export const CLIENT_FINANCIALS_DETAILS_FIELDS_EXPANDERS: Partial<Record<FinancialKeys, string>> = {
  trade_discounts: 'gross_revenue',
  returns_refunds: 'gross_revenue',
  product_cogs: 'dilution',
  cost_of_goods_sold: 'dilution',
  payroll: 'gross_margin',
  sales_and_marketing: 'gross_margin',
  research_and_development: 'gross_margin',
  other_operating_expenses: 'gross_margin',
  interest_expense: 'operating_income',
  depreciation_and_amortization: 'operating_income',
  taxes: 'operating_income',
  other_expenses: 'operating_income',
  other_income: 'operating_income',
  cash_and_cash_equivalents: 'total_current_assets_above',
  accounts_receivable_net: 'total_current_assets_above',
  inventory: 'total_current_assets_above',
  prepaid_expenses: 'total_current_assets_above',
  other_current_assets: 'total_current_assets_above',
  net_ppe: 'total_current_assets',
  intangible_goodwill: 'total_current_assets',
  other_long_term_assets: 'total_current_assets',
  accounts_payable: 'total_assets',
  accrued_expenses: 'total_assets',
  taxes_payable: 'total_assets',
  deferred_revenue_and_deposits: 'total_assets',
  dwight_funding_loan: 'total_assets',
  credit_card_debt: 'total_assets',
  gift_cards: 'total_assets',
  other_short_term_debt: 'total_assets',
  other_current_liabilities: 'total_assets',
  convertible_notes_debt: 'total_current_liabilities',
  ppp: 'total_current_liabilities',
  eidl: 'total_current_liabilities',
  other_long_term_debt: 'total_current_liabilities',
  other_long_term_liabilities: 'total_current_liabilities',
  total_long_term_liabilities: 'total_current_liabilities',
  common_and_treasury_stock: 'total_liabilities',
  additional_paid_in_capital: 'total_liabilities',
  convertible_notes_equity: 'total_liabilities',
  retained_earnings: 'total_liabilities',
  other_shareholders_equity: 'total_liabilities',
}

export const CLIENT_FINANCIALS_HEADER_FIELDS_WITH_EXPAND_BY_TYPE = {
  [OngoingReportingType.IncomeStatement]: [
    'gross_revenue',
    'dilution',
    'gross_margin',
    'operating_income',
  ],
  [OngoingReportingType.BalanceSheet]: [
    'total_current_assets_above',
    'total_current_assets',
    'total_long_term_assets',
    'total_assets',
    'total_current_liabilities',
    'total_liabilities',
  ],
}

export const CLIENT_FINANCIALS_BOTTOM_LINE_FIELDS = [
  'net_income',
  'ebitda_margin',
  'total_shareholders_equity',
  'quick_ratio',
  'current_ratio',
]

export const CLIENT_FINANCIALS_PERCENT_FIELDS: FinancialKeys[] = [
  'dilution',
  'gross_margin',
  'ebitda_margin',
]

export const CLIENT_FINANCIALS_RATIO_FIELDS: FinancialKeys[] = ['quick_ratio', 'current_ratio']

export const CLIENT_FINANCIALS_FIELDS: {
  [OngoingReportingType.IncomeStatement]: IncomeStatementKeys[]
  [OngoingReportingType.BalanceSheet]: BalanceSheetKeys[]
} = {
  [OngoingReportingType.IncomeStatement]: [
    'gross_revenue',
    'trade_discounts',
    'returns_refunds',
    'net_revenue',
    'dilution',
    'product_cogs',
    'cost_of_goods_sold',
    'gross_profit',
    'gross_margin',
    'payroll',
    'sales_and_marketing',
    'research_and_development',
    'other_operating_expenses',
    'total_operating_expenses',
    'operating_income',
    'interest_expense',
    'depreciation_and_amortization',
    'taxes',
    'other_expenses',
    'other_income',
    'net_income',
    'ebitda_margin',
    'collections',
  ],
  [OngoingReportingType.BalanceSheet]: [
    'cash_and_cash_equivalents',
    'accounts_receivable_net',
    'inventory',
    'prepaid_expenses',
    'other_current_assets',
    'total_current_assets',
    'net_ppe',
    'intangible_goodwill',
    'other_long_term_assets',
    'total_long_term_assets',
    'total_assets',
    'accounts_payable',
    'accrued_expenses',
    'taxes_payable',
    'deferred_revenue_and_deposits',
    'dwight_funding_loan',
    'credit_card_debt',
    'gift_cards',
    'other_short_term_debt',
    'other_current_liabilities',
    'total_current_liabilities',
    'convertible_notes_debt',
    'ppp',
    'eidl',
    'other_long_term_debt',
    'other_long_term_liabilities',
    'total_long_term_liabilities',
    'total_liabilities',
    'common_and_treasury_stock',
    'additional_paid_in_capital',
    'convertible_notes_equity',
    'retained_earnings',
    'other_shareholders_equity',
    'total_shareholders_equity',
    'quick_ratio',
    'current_ratio',
  ],
}

export interface IFundingRequest {
  id: string
  status: string
  amount: number
  amountType: string
  files: { [key: string]: IFile | IFile[] }
  dueReports: IRequiredReport[]
  bbcSignatureFile?: IFile
}

export interface IARAPReconcileSummary {
  totalFromReport?: number
  totalCalculated?: number
  prebilling?: number
}

export interface IBorrowingBaseFileSheet {
  id: string
  borrowingBaseId: string
  type: string
  fileId: string
  file?: IFile
  sheetName: string
  data: any
  createdAt: string
  updatedAt: string
}

export interface IBorrowingBase {
  id: string
  requestedAmount: number
  requestedAmountType: FundingRequestAmountType
  status: FundingRequestStatus
  recordDate: string
  clientName: string
  clientInfo?: IClientInfo
  files?: IFile[]
  fileSheets?: IBorrowingBaseFileSheet[]
  clientCollateral?: IClientCollateral
  isCollateralEligibilityReviewRequired?: boolean
  isCollateralEligibilityHasCriticalDebtor?: boolean
  isEntityMappingRequired?: boolean
  isDebtorMappingRequired?: boolean
  isCreditorMappingRequired?: boolean
  inventoryConverterTemplateId?: string | null
  arReconcileSummary: IARAPReconcileSummary
  apReconcileSummary: IARAPReconcileSummary
  boxLink?: string | null
  previousBBC: IBorrowingBase
  clientBoxLink?: string
  preProcessed: boolean
  postProcessing: boolean
  fundings?: IFunding[]
  wireCount: number
  isBlocked: boolean
  isTest: boolean
  projectedNewLoanAmount: number
  isReminderEnabled?: boolean
}

export interface IBorrowingBaseIndex {
  id: string
  clientName: string
  clientId: string
  clientIconUrl: string
  status: FundingRequestStatus
  isTest: boolean
  amount: number
  recordDate: string
  boxLink: string | null
  arAvailability?: number
  inventoryAvailabilityPreSubLimit?: number
  inventoryAvailabilityPostSubLimit?: number
  keywords: string
}

export interface IFunding {
  id: string
  borrowingBaseId: string | null
  borrowingBase: IBorrowingBase | null
  amount: number
  sentDate?: string
  feeId: string | null
  fee: IFee | null
}

export interface IBorrowingBaseStats {
  contras: string[]
  ineligibleReceivables: {
    debtor: string
    ineligibleCategory: string
    notesReason: string
  }[]
  ineligibleInventory: {
    type: string
    description: string
  }[]
  priorityPayables: string[]
  ineligibleLocations: string[]
}

export interface IClientCollateral {
  id: string
  borrowingBaseId?: string
  borrowingBase?: IBorrowingBase
  recordDate: string
  arOtherReservesFixed: number
  arOtherReservesPercent: number
  arOtherReservesField: string
  invOtherReservesFixed: number
  invOtherReservesPercent: number
  invOtherReservesField: string
  totalReceivables: number
  lessIneligibleReceivables: number
  eligibleReceivables: number
  arAdvanceRate: number
  arAvailability: number
  ar_1To_30Days: number
  ar_31To_60Days: number
  ar_61To_90Days: number
  ar_91PlusDays: number
  totalGrossAr: number
  ap_0To_60Days: number
  ap_1To_30Days: number
  ap_31To_60Days: number
  ap_61To_90Days: number
  ap_91PlusDays: number
  inv_0To_90DaysSinceLanded: number
  inv_91To_180DaysSinceLanded: number
  inv_181To_270DaysSinceLanded: number
  inv_271To_360DaysSinceLanded: number
  inv_361PlusDaysSinceLanded: number
  inv_361PlusDaysUntilExpiration: number
  inv_271To_360DaysUntilExpiration: number
  inv_181To_270DaysUntilExpiration: number
  inv_91To_180DaysUntilExpiration: number
  inv_0To_90DaysUntilExpiration: number
  totalAp: number
  finishedGoods: number
  rawMaterials: number
  other: number
  totalGrossInventory: number
  ineligibleSkus: number
  ineligibleLocationsAndInTransit: number
  slowAgedOrExpired: number
  over_90Payables: number
  invOtherMiscReserves: number
  totalIneligibleInventory: number
  over_90DaysAndCreditsPastDue: number
  crossAge: number
  ineligibleDebtors: number
  contras: number
  concentration: number
  creditLimits: number
  arOtherMiscReserves: number
  totalIneligibleAr: number
  eligibleInventoryCost: number
  costValueOfFinishedGoodsInventory: number
  costValueOfRawMaterialsInventory: number
  costValueOfInventory: number
  inventoryAdvanceRate: number
  rawMaterialsAdvanceRate: number
  nolvAdvanceFinishedGoods: number
  nolvAdvanceRawMaterials: number
  nolvAdvance: number
  nolvAdvanceRate: number
  nolvPercentOfCost: number
  rmNolv: number
  rmInventoryAdvanceRateNolv: number
  nolvCalculationFinishedGoods: number
  nolvCalculationRawMaterials: number
  nolvCalculation: number
  inventoryAvailabilityPreSubLimit: number
  inventoryAvailabilityPostSubLimit: number
  netAvailability: number
  netAvailabilityWithOveradvances: number
  maximumLine: number
  finalNetAvailability: number
  excessLoanAvailability: number
  currentLoanAmount: number
  newLoanAmount: number
  wireCosts: number
  priorLoanAmount: number
  realizedInterest: number
  miscFees: number
  collections: number
  adjustments: number
  returnedCollections: number
  termLoanPrincipal: number
  termLoanBalance: number
  totalCurrentLoanBalance: number
  overadvances?: number[]
}

export interface IPreviousCollateralDateOptions {
  recordDate: string
}

export enum EligibilityStatus {
  New = 'NEW',
  Eligible = 'ELIGIBLE',
  Ineligible = 'INELIGIBLE',
}

export enum IneligibleCategory {
  Foreign = 'Foreign',
  Government = 'Government',
  Intercompany = 'Intercompany',
  Other = 'Other',
  Eligible = 'Eligible',
  Distressed = 'Distressed',
  Affiliate = 'Affiliate',
  Ineligible = 'Ineligible',
}

export interface IArIneligibility {
  id: string
  ineligibleCategory: string
  creditLimits: number
  concentrationLimit: number
  totalAR: number
  totalIneligible: number
  notesReason: string
  isCritical: boolean
  isContra: boolean
  entityInfo?: IEntityInfo
  lastActive?: string
  lastAmount?: number
  isNew?: boolean
}

export interface IArIneligibilityData {
  data: IArIneligibility[]
  dictionary: {
    debtors: string[]
  }
  totals: {
    totalItems: number
  }
}

export interface IExtraReserve {
  entityId: string
  creditor: string
  priorityPayable: string
  over0Payables: number
  over30Payables: number
  over60Payables: number
  over90Payables: number
  notes?: string
  id?: string
  lastAmount?: number
  lastActive?: string
  type?: string
  entityInfo?: IEntityInfo
}

export interface IExtraReserveData {
  data: IExtraReserve[]
  dictionary: {
    creditors: string[]
  }
  totals: { totalItems: number }
}

export enum SkuTypes {
  Empty = 'NEW',
  Finished = 'Finished Goods',
  InProcess = 'Work in Process',
  Raw = 'Raw Materials',
  Packaging = 'Packaging',
  Other = 'Other/Unspecified',
}

export const SKU_TYPES_LIST = [
  {
    value: SkuTypes.Finished,
    label: SkuTypes.Finished,
  },
  {
    value: SkuTypes.InProcess,
    label: SkuTypes.InProcess,
  },
  {
    value: SkuTypes.Raw,
    label: SkuTypes.Raw,
  },
  {
    value: SkuTypes.Packaging,
    label: SkuTypes.Packaging,
  },
  {
    value: SkuTypes.Other,
    label: SkuTypes.Other,
  },
]

export interface IInventoryIneligibility {
  id: string
  brandCategory: string
  recordDate: string
  sku: string
  value: string
  type?: string
  notes?: string
  description?: string
  location?: string
  totalValue?: number
  eligibility: boolean
  clientName: string
  clientInfo?: IClientInfo
  isNew?: boolean
  isCanadaMexicoLocation?: boolean
  lastActive?: string
  lastAmount?: number
  inventoryLocation?: IInventoryLocation
}

export interface IInventoryIneligibilityData {
  data: IInventoryIneligibility[]
  totals: {
    totalItems: number
  }
  invalid?: boolean
  categories?: string[]
  vendors?: string[]
  skusChanged?: string
}

export const NOT_ALLOWED_INVENTORY_INELIGIBILITY_FIELDS = [
  'sku',
  'lotNumber',
  'onHand',
  'avgUnitCost',
  'totalValue',
  'daysSinceLanded',
  'daysUntilExpiration',
  'weeksOfSupply',
  'sales',
  'salesUnits',
  'expirationDate',
  'landedDate',
  'recordDate',
]

export const INVENTORY_INELIGIBILITY_FIELDS = {
  sku: {
    category: 'Unique SKUs',
    dbField: 'sku',
    reason: 'SKU',
  },
  location: {
    category: 'Unique Locations',
    dbField: 'location',
    reason: 'Location',
  },
  type: {
    category: 'Unique Types',
    dbField: 'type',
    reason: 'Type',
  },
  season: {
    category: 'Unique Seasons',
    dbField: 'season',
    reason: 'Season',
  },
  brandCategory: {
    category: 'Unique Category',
    dbField: 'brand_category',
    reason: 'Category',
  },
  industrySubcategory: {
    category: 'Unique Sub Category',
    dbField: 'industry_subcategory',
    reason: 'Sub Category',
  },
  style: {
    category: 'Unique Style',
    dbField: 'style',
    reason: 'Style',
  },
  size: {
    category: 'Unique Sizes',
    dbField: 'size',
    reason: 'Size',
  },
  flavor: {
    category: 'Unique Flavor',
    dbField: 'flavor',
    reason: 'Flavor',
  },
  color: {
    category: 'Unique Color',
    dbField: 'color',
    reason: 'Color',
  },
  lotNumber: {
    category: 'Unique Lot Numbers',
    dbField: 'lot_number',
    reason: 'Lot Number',
  },
  custom_1: {
    category: 'Custom 1',
    dbField: 'custom_1',
    reason: 'Custom 1',
  },
  custom_2: {
    category: 'Custom 2',
    dbField: 'custom_2',
    reason: 'Custom 2',
  },
  custom_3: {
    category: 'Custom 3',
    dbField: 'custom_3',
    reason: 'Custom 3',
  },
  custom_4: {
    category: 'Custom 4',
    dbField: 'custom_4',
    reason: 'Custom 4',
  },
  custom_5: {
    category: 'Custom 5',
    dbField: 'custom_5',
    reason: 'Custom 5',
  },
  onHand: {
    category: 'Unique On Hands',
    dbField: 'on_hand',
    reason: 'On Hand',
  },
  avgUnitCost: {
    category: 'Unique Average Unit Costs',
    dbField: 'avg_unit_cost',
    reason: 'Average Unit Cost',
  },
  totalValue: {
    category: 'Unique Total Values',
    dbField: 'total_value',
    reason: 'Total Value',
  },
  landedDate: {
    category: 'Unique Landed Dates',
    dbField: 'landed_date',
    reason: 'Landed Date',
  },
  expirationDate: {
    category: 'Unique Expiration Dates',
    dbField: 'expiration_date',
    reason: 'Expiration Date',
  },
  daysSinceLanded: {
    category: 'Unique Days Since Landed',
    dbField: 'days_since_landed',
    reason: 'Days Since Landed',
  },
  daysUntilExpiration: {
    category: 'Unique Days Until Expiration',
    dbField: 'days_until_expiration',
    reason: 'Days Until Expiration',
  },
  sales: {
    category: 'Unique Sales',
    dbField: 'sales_amount',
    reason: 'Sales',
  },
  salesUnits: {
    category: 'Unique Sales Units',
    dbField: 'sales_units',
    reason: 'Sales Units',
  },
  weeksOfSupply: {
    category: 'Unique Weeks Of Supply',
    dbField: 'weeks_of_supply',
    reason: 'Weeks Of Supply',
  },
  department: {
    category: 'Unique Department',
    dbField: 'department',
    reason: 'Department',
  },
  dwightCategory: {
    category: 'Unique Standard Category',
    dbField: 'dwight_category',
    reason: 'Standard Category',
  },
  dwightSubcategory: {
    category: 'Unique Standard Sub Category',
    dbField: 'dwight_subcategory',
    reason: 'Standard Sub Category',
  },
  dwightSize: {
    category: 'Unique Standard Size',
    dbField: 'dwight_size',
    reason: 'Standard Size',
  },
  dwightDepartment: {
    category: 'Unique Standard Department',
    dbField: 'dwight_department',
    reason: 'Standard Department',
  },
}

export type InventoryFieldKeys = keyof typeof INVENTORY_INELIGIBILITY_FIELDS

export const INVENTORY_DISTRIBUTION_LABELS = Object.keys(INVENTORY_INELIGIBILITY_FIELDS).reduce(
  (acc, key) => {
    acc[key] = INVENTORY_INELIGIBILITY_FIELDS[key].reason
    return acc
  },
  {},
)

export const INVENTORY_DISTRIBUTION_DB_FIELDS = Object.keys(INVENTORY_INELIGIBILITY_FIELDS).reduce(
  (acc, key) => {
    acc[key] = INVENTORY_INELIGIBILITY_FIELDS[key].dbField
    return acc
  },
  {},
)

export const INVENTORY_DB_FIELDS_TO_LABELS = Object.keys(INVENTORY_INELIGIBILITY_FIELDS).reduce(
  (acc, key) => {
    acc[INVENTORY_INELIGIBILITY_FIELDS[key].dbField] = INVENTORY_INELIGIBILITY_FIELDS[key].reason
    return acc
  },
  {},
)

export const INVENTORY_ATTRIBUTE_FIELDS: InventoryFieldKeys[] = [
  'type',
  'season',
  'size',
  'brandCategory',
  'industrySubcategory',
  'flavor',
  'style',
  'color',
  'custom_1',
  'custom_2',
  'custom_3',
  'custom_4',
  'custom_5',
  'department',
  'dwightCategory',
  'dwightSubcategory',
  'dwightSize',
  'dwightDepartment',
]

export const SALES_BY_SKU_ATTRIBUTE_FIELDS = [
  'type',
  'season',
  'size',
  'brand_category',
  'industry_subcategory',
  'flavor',
  'style',
  'color',
  'custom_1',
  'custom_2',
  'custom_3',
  'custom_4',
  'custom_5',
  'department',
  'dwight_category',
  'dwight_subcategory',
  'dwight_size',
  'dwight_department',
]

export interface IInventoryIneligibilityField {
  id: string
  field: InventoryFieldKeys
  ineligibleReason: IneligibleReasons
  clientName: string
  clientInfo?: IClientInfo
}

export enum CUSTOM_RULES_FIELD {
  Location = 'location',
  SKU = 'sku',
  Description = 'description',
  Type = 'type',
  Season = 'season',
  Size = 'size',
  Flavor = 'flavor',
  Style = 'style',
  Color = 'color',
  'Category' = 'brandCategory',
  'Sub Category' = 'industrySubcategory',
  'Lot Number' = 'lotNumber',
  'On Hand' = 'onHand',
  'Average Unit Cost' = 'avgUnitCost',
  'Total Value' = 'totalValue',
  'Landed Date' = 'landedDate',
  'Expiration Date' = 'expirationDate',
  'Days Since Landed' = 'daysSinceLanded',
  'Days Until Expiration' = 'daysUntilExpiration',
  'Sales Units' = 'salesUnits',
  'Sales' = 'sales',
  'Weeks Of Supply' = 'weeksOfSupply',
  'Custom 1' = 'custom_1',
  'Custom 2' = 'custom_2',
  'Custom 3' = 'custom_3',
  'Custom 4' = 'custom_4',
  'Custom 5' = 'custom_5',
  'Department' = 'department',
  'Standard Category' = 'dwightCategory',
  'Standard Subcategory' = 'dwightSubcategory',
  'Standard Size' = 'dwightSize',
  'Standard Department' = 'dwightDepartment',
}

export const INVENTORY_FIELD_TO_LABEL = invert(CUSTOM_RULES_FIELD)

export enum CUSTOM_RULES_OPERATOR {
  '>' = '>',
  '=' = '=',
  '<' = '<',
}

export const CUSTOM_RULES_FIELD_TYPES = {
  recordDate: 'date',
  sku: 'string',
  type: 'string',
  location: 'string',
  season: 'string',
  size: 'string',
  lotNumber: 'string',
  onHand: 'number',
  avgUnitCost: 'number',
  totalValue: 'number',
  landedDate: 'date',
  expirationDate: 'date',
  daysSinceLanded: 'number',
  daysUntilExpiration: 'number',
  sales: 'number',
  salesUnits: 'number',
  weeksOfSupply: 'number',
  custom_1: 'string',
  custom_2: 'string',
  custom_3: 'string',
  custom_4: 'string',
  custom_5: 'string',
  flavor: 'string',
  style: 'string',
  color: 'string',
  brandCategory: 'string',
  industrySubcategory: 'string',
}

export interface IInventoryIneligibilityCustomRule {
  id: string
  field: CUSTOM_RULES_FIELD
  label: string
  operator: CUSTOM_RULES_OPERATOR
  value: any
  values: any[]
  eligibility: EligibilityStatus
  overrides: string[]
  clientName: string
  clientInfo?: IClientInfo
  children: IInventoryIneligibilityCustomRule[]
}

export interface IInventoryIneligibilityAnalisysSKU {
  description: string
  sku: string
  totalValue: number
  percent: number
  type: string
  location: string
  weeksOfSupply: number
  eligible: boolean
  totalValueAll: number
  totalItems: number
  totalAvailability: number
}

export interface IInventoryIneligibilityAnalisysSKUData {
  data: IInventoryIneligibilityAnalisysSKU[]
  totals: {
    totalItems: number
    totalValueTop5: number
    totalValueTop5Percent: number
    totalAvailabilityTop5: number
    totalValueTop10: number
    totalValueTop10Percent: number
    totalAvailabilityTop10: number
    totalValueAll: number
    totalAvailabilityAll: number
  }
}

export interface IInventoryDistributionAnalysisData {
  inventoryDates: { id: string; recordDate: string }[]
  data: IInventoryDistributionAnalysis[]
}

export interface IInventoryDistributionAnalysisCategories {
  filterDropdownValues: {
    [key: string]: string[]
  }
  distributionPicklist: string[]
}

export interface IInventoryDistributionAnalysis {
  distribution: string
  eligibility: EligibilityStatus
  values: {
    [recordDate: string]: {
      value: number
      percent: number
    }
  }
  mappedLocation?: string
}
export interface ISalesDistributionAnalysisData {
  dates: string[]
  data: ISalesDistributionAnalysis[]
}

export interface ISalesDistributionAnalysis {
  distribution: string
  values: {
    [recordDate: string]: {
      value: number
      percentValue: number
      units: number
      percentUnits: number
    }
  }
  totalSales: number
  totalUnits: number
}

export interface IIneligibleReasons {
  [key: string]: number
}

export interface IArRawDataItem {
  id?: string
  clientInfo?: IClientInfo
  invoiceDate?: string
  asOfDate?: string
  balance?: number
  debtor?: string
  linkedName?: string
  predictions?: string[]
  invoice?: string
  daysPastInvoice?: number
  memo?: string
  prediction?: string
  totalItems?: number
}

export interface IApRawDataItem {
  id?: string
  clientInfo?: IClientInfo
  invoiceDate?: string
  asOfDate?: string
  balance?: number
  creditor?: string
  linkedName?: string
  predictions?: string[]
  invoice?: string
  daysPastInvoice?: number
  memo?: string
  prediction?: string
  totalItems?: number
}

export interface IAlias {
  id?: string
  originalName?: string
  aka?: string
  linkedName?: string
  prediction?: string
  totalItems?: number
  totalUnmapped?: number
  confidence?: number
  predictionConfidence?: number
  isNew?: boolean
  lastMappedDate?: string
  clientName?: string
  isNewEntity?: boolean
  entityId?: string
  totalAmount?: number
}

export interface IOveradvancePayment {
  id?: string
  overadvanceId: string
  recordDate: string
  outstandingBalance?: number
  amount: number
}

export interface IOveradvance {
  id: string
  clientName: string
  clientInfo?: IClientInfo
  overadvance: number
  startDate: string
  endDate: string | null
  fee: number | null
  rate?: number
  floor: number
  marginAbovePrime: number
  purpose?: string
  priority: number | null
  currentBalance?: number
  createdAt?: string
  overadvancePayments?: IOveradvancePayment[]
  summaryOfChanges?: string
}

export const STANDARDISED_INVENTORY_DETAIL_DOCUMENT_REQUIRED_COLUMNS = [
  'sku',
  'description',
  'location',
]

export const STANDARDIZED_INVENTORY_DETAIL_DOCUMENT_VALUE_COLUMNS = [
  'onHand',
  'avgUnitCost',
  'totalValue',
]

export const STANDARDISED_MASTER_INVENTORY_OPTIONAL_FIELD_MAPPING_WITH_SPACES = {
  sku: 'sku',
  description: 'description',
  'type (e.g. work in process, finished goods)': 'type',
  category: 'brand_category',
  'collaboration (y/n)': 'collaboration',
  'season (apparel)': 'season',
  'flavor (food & bev)': 'flavor',
  'color (apparel)': 'color',
  'size (e.g., xs, 12oz, 3 pack)': 'size',
  season: 'season',
  size: 'size',
  type: 'type',
  color: 'color',
  flavor: 'flavor',
  style: 'style',
  'sub category': 'industry_subcategory',
  subcategory: 'industry_subcategory',
  'brand category': 'brand_category',
  department: 'department',
  'dwight category': 'dwight_category',
  'dwight subcategory': 'dwight_subcategory',
  'dwight size': 'dwight_size',
  'dwight department': 'dwight_department',
  'standard category': 'dwight_category',
  'standard subcategory': 'dwight_subcategory',
  'standard sub category': 'dwight_subcategory',
  'standard size': 'dwight_size',
  'standard department': 'dwight_department',
}

const filterObjectAndAddUnderscore = (obj: any) =>
  Object.keys(obj).reduce((acc, key) => {
    if (key.includes(' ')) {
      acc[key.replace(/ /g, '_')] = obj[key]
    }
    return acc
  }, {})

export const STANDARDISED_MASTER_INVENTORY_OPTIONAL_FIELD_MAPPING = {
  ...STANDARDISED_MASTER_INVENTORY_OPTIONAL_FIELD_MAPPING_WITH_SPACES,
  ...filterObjectAndAddUnderscore(STANDARDISED_MASTER_INVENTORY_OPTIONAL_FIELD_MAPPING_WITH_SPACES),
}
const STANDARDISED_MASTER_INVENTORY_OPTIONAL_FIELD_MAPPING_WITH_SPACES_KEYS = {
  location: 'location',
  'on hand': 'onHand',
  'avg unit cost': 'avgUnitCost',
  'landed date': 'landedDate',
  'expiration date (food & bev, beauty)': 'expirationDate',
  'custom 1': 'custom_1',
  'custom 2': 'custom_2',
  'custom 3': 'custom_3',
  'custom 4': 'custom_4',
  'custom 5': 'custom_5',
  'lot number': 'lotNumber',
  'expiration date': 'expirationDate',
  'total value': 'totalValue',
}

export const STANDARDISED_INVENTORY_DETAIL_DOCUMENT_FIELD_MAPPING = {
  ...STANDARDISED_MASTER_INVENTORY_OPTIONAL_FIELD_MAPPING_WITH_SPACES_KEYS,
  ...filterObjectAndAddUnderscore(
    STANDARDISED_MASTER_INVENTORY_OPTIONAL_FIELD_MAPPING_WITH_SPACES_KEYS,
  ),
  ...Object.keys(STANDARDISED_MASTER_INVENTORY_OPTIONAL_FIELD_MAPPING).reduce((acc, key) => {
    acc[key] = STANDARDISED_MASTER_INVENTORY_OPTIONAL_FIELD_MAPPING[key]
      .toLowerCase()
      .replace(/[-_][a-z]/g, (group: string) => group.slice(-1).toUpperCase())
    return acc
  }, {}),
}

export const STANDARDISED_SALES_BY_SKU_REQUIRED_COLUMNS = [
  'sku',
  'record_date',
  'last_6_month_sales_units',
  'last_6_month_sales_amount',
]

export const STANDARDIZED_ATTRIBUTE_COLUMNS = [
  'dwight_category',
  'dwight_subcategory',
  'dwight_size',
  'dwight_department',
]

export const STANDARDISED_SALES_BY_SKU_FIELD_MAPPING = {
  sku: 'sku',
  record_date: 'recordDate',
  description: 'description',
  last_6_month_sales_units: 'l6mSalesUnits',
  last_6_month_sales_amount: 'l6mSalesAmount',
}

export const STANDARDISED_SALES_BY_SKU_DETAIL_REQUIRED_COLUMNS = [
  'sku',
  'record_date',
  'period',
  'sales_units',
  'sales_amount',
]

export const SALES_BY_SKU_DETAIL_NON_MONTHLY_COLUMNS = {
  sku: 'sku',
  record_date: 'record_date',
  'record date': 'record_date',
  period: 'period',
  sales_units: 'sales_units',
  'sales units': 'sales_units',
  'sales amount': 'sales_amount',
  sales_amount: 'sales_amount',
}

export const STANDARDISED_SALES_BY_SKU_DETAIL_FIELD_MAPPING = {
  sku: 'sku',
  description: 'description',
  period: 'period',
  record_date: 'recordDate',
  recorddate: 'recordDate',
  sales_units: 'salesUnits',
  salesunits: 'salesUnits',
  sales_amount: 'salesAmount',
  salesamount: 'salesAmount',
}

export interface IInventoryMappingFields {
  id: string
  sku: string
  description: string
  brandCategory: string
  industrySubcategory: string
  season: string
  type: string
  size: string
  flavor: string
  style: string
  color: string
  eligibility?: EligibilityStatus
  notes?: string
}

export interface IInventoryMappingFieldsData {
  data: IInventoryMappingFields[]
  totalItems: number
}

export interface ISalesBySKUVisualizationTotals {
  top5: ISalesBySKUVisualization
  top10: ISalesBySKUVisualization
  all: ISalesBySKUVisualization
}

export interface ISalesBySKUVisualizationData {
  data: ISalesBySKUVisualization[]
  totalItems: number
  totalDuplicateSkus: number
  totalUniqueDuplicateSkus: number
  totals: ISalesBySKUVisualizationTotals
}

export interface ISalesBySKUVisualization {
  distribution: string
  sku: string
  onHand: number
  totalValue: number
  totalInventoryPercent: number
  unitSales: number
  totalSales: number
  totalSalesPercent: number
  totalCOGS: number
  weeklyCOGS: number
  weeksOfSupply: number
  totalIneligible: number
  monthsOfSales: number
  monthsOfSalesCount: number
  totalSalesValue: number
  totalInventoryValue: number
  totalItems: number
  previousOnHandDifference: number
  previousTotalValueDifference: number
}

export interface ISalesBySKUVisualizationFilters {
  filterDropdownValues: {
    [key: string]: string[]
  }
  distributionPicklist: string[]
  inventoryDates: string[]
  latestSalesDate: string
  earliestSalesDate: string
}

export interface IARRollforwardDetail {
  debtor: string
  invoice: string
  newInvoices: number
  impliedCollections: number
  actualCollections: number
  impliedReal: number
  newCredits: number
  creditsApplied: number
  totalCount: number
  invoiceDate: string
  paidDate: string
}

export interface IAPRollforwardDetail {
  invoice: string
  newBills: number
  impliedPayments: number
  newCredits: number
  creditsApplied: number
  totalCount: number
  invoiceDate: string
  paidDate: string
}

export interface IARRollforwardSummary {
  entityId: string
  label: string
  invoiceCount?: number
  newBalance: number
  percent: number
  newInvoices: number
  impliedCollections: number
  actualCollections: number
  impliedReal: number
  impliedDilution: number
  newCredits: number
  creditsApplied: number
  rows?: IARRollforwardDetail[]
}

export interface IARRollforwardGraphDataItem {
  recordDate: string
  header: string
  newInvoices: number
  actualCollections: number
  impliedCollections: number
  impliedDilution: number
}

export interface IARRollforwardGraphData {
  headers: string[]
  data: IARRollforwardGraphDataItem[]
  minDate: string | null
  maxDate: string | null
  debtors: string[]
}

export interface IAPRollforwardSummary {
  entityId: string
  priorityPayable: number
  label: string
  invoiceCount?: number
  newBalance: number
  percent: number
  newBills: number
  impliedPayments: number
  newCredits: number
  creditsApplied: number
  rows?: IAPRollforwardDetail[]
}

export interface IArRollforwardData {
  currentFunding?: IBorrowingBase
  previousFunding?: IBorrowingBase
  data: IARRollforwardSummary[]
  totalItems: number
  totals: IARRollforwardSummary[]
  ineligibleCategories: string[]
}

export interface IApRollforwardData {
  currentFunding?: IBorrowingBase
  previousFunding?: IBorrowingBase
  data: IAPRollforwardSummary[]
  totalItems: number
  totals: IAPRollforwardSummary[]
}

export enum ReserveKind {
  Absolute = 'absolute',
  Percent = 'percent',
}

export interface IReserve {
  id: string
  clientName: string
  type: string
  category: string
  amount: number
  percent?: number
  reason: string | null
  kind: ReserveKind
  field: string | null
}

export interface IInventoryIneligibleStats {
  data: {
    category: string
    values: { [key: string]: number }
    rows: IInventoryIneligibleStatsReason[]
  }[]
  total: number
  dates: string[]
}

export interface IInventoryIneligibleStatsReason {
  category: string
  reason: string
  values: { [key: string]: number }
  totalCount: number
  rows: IInventoryIneligibleStatsDetail[]
}

export interface IInventoryIneligibleStatsDetail {
  sku: string
  description: string
  category: string
  reason: string
  values: { [key: string]: number }
  totalCount: number
}

export interface IARIneligibleStatsDetailsInvoice {
  invoice: string
  totalCount: number
  value_0: number
  value_1?: number
  value_2?: number
  value_3?: number
  value_4?: number
}

export interface IARIneligibleStatsDetails {
  debtor: string
  totalCount: number
  value_0: number
  value_1?: number
  value_2?: number
  value_3?: number
  value_4?: number
  rows: IARIneligibleStatsDetailsInvoice[]
}

export interface IARIneligibleStats {
  label: string
  values: number[]
  rows: IARIneligibleStatsDetails[]
}

export const QUICKBOOK_FIELDS = {
  '!trns': '!TRNS',
  trnstype: 'TRNSTYPE',
  date: 'DATE',
  accnt: 'ACCNT',
  name: 'NAME',
  amount: 'AMOUNT',
  docnum: 'DOCNUM',
  memo: 'MEMO',
}

export interface QuickbookReferenceData {
  '!TRNS': string
  TRNSTYPE: string
  DATE: string
  ACCNT: string
  NAME: string
  AMOUNT: number
  DOCNUM: string
  MEMO: string
}

interface IArSummaryStatsBase {
  debtor: string
  invoice: string | null
  daysPastInvoice: number | null
  ar_1To_30Days: number
  ar_31To_60Days: number
  ar_61To_90Days: number
  ar_91PlusDays: number
  ineligible: number
  eligible: number
  total: number
  percent: number
  totalCount?: number
}

export interface IArSummaryStats extends IArSummaryStatsBase {
  entityId: string
  debtor: string
  memo: string
  ar_1To_30DaysChanged?: number
  ar_31To_60DaysChanged?: number
  ar_61To_90DaysChanged?: number
  ar_91PlusDaysChanged?: number
  ineligibleChanged?: number
  eligibleChanged: number
  totalChanged?: number
  invoiceCount?: number
  invoiceCountChanged?: number
  daysPastInvoiceChanged?: number
  ineligiblePerDebtor: number
  total: number
  percent: number
  percentChanged: number
  ineligibleCategory: boolean
  rows?: IArSummaryStats[]
}

interface IArSummaryStatsTotals {
  ar_1To_30Days: number
  ar_1To_30DaysPercent: number
  ar_31To_60Days: number
  ar_31To_60DaysPercent: number
  ar_61To_90Days: number
  ar_61To_90DaysPercent: number
  ar_91PlusDays: number
  ar_91PlusDaysPercent: number
  ineligible: number
  eligible: number
  ineligiblePercent: number
  eligiblePercent: number
  total: number
}

export interface IArSummaryStatsData {
  data: IArSummaryStats[]
  totals: IArSummaryStatsTotals
}

interface IApSummaryStatsBase {
  memo: string
  invoice: string | null
  daysPastInvoice: number | null
  ap_1To_30Days: number
  ap_31To_60Days: number
  ap_61To_90Days: number
  ap_91PlusDays: number
  total: number
  percent: number
  totalCount?: number
}

export interface IApSummaryStats extends IApSummaryStatsBase {
  entityId: string
  creditor: string
  priorityPayable: number | null
  ap_1To_30DaysChanged: number
  ap_31To_60DaysChanged: number
  ap_61To_90DaysChanged: number
  ap_91PlusDaysChanged: number
  invoiceCount?: number
  invoiceCountChanged?: number
  daysPastInvoiceChanged?: number
  percentChanged: number
  totalChanged: number
  rows?: IApSummaryStats[]
}
interface IApSummaryStatsTotals {
  ap_1To_30Days: number
  ap_1To_30DaysPercent: number
  ap_31To_60Days: number
  ap_31To_60DaysPercent: number
  ap_61To_90Days: number
  ap_61To_90DaysPercent: number
  ap_91PlusDays: number
  ap_91PlusDaysPercent: number
  total: number
}

export interface IApSummaryStatsData {
  data: IApSummaryStats[]
  totals: IApSummaryStatsTotals
}

export interface IFlags {
  id?: string
  flagName?: string
  flagType?: string
  flagLod?: string
  message?: string
  impactToAvailability?: string
  sourceId?: string
  resolved?: boolean
  feedback?: Feedback
  feedbacks?: IFlagFeedback[]
  invoice?: string
  invoiceCount?: number
  debtor?: string
  notes?: INote
  borrowingBase?: IBorrowingBase
  clientInfo?: IClientInfo
  ongoingReporting?: IOngoingReporting
  ongoingReportingId?: string
  opsReporting?: IOPSReporting
  flagColor: string | null
  masterFlag?: IMasterFlag
  entityInfo?: IEntityInfo
  recordDate?: string
}

export interface IFlagData {
  data: IFlags[]
  totalItems: number
  newFlagCount: number // need to setup
}

export interface IMasterFlag {
  id: string
  flagName: string
  tooltipMessage: string
  yellowThreshold: number | null
  redThreshold: number | null
  alwaysYellow: boolean
  alwaysRed: boolean
}

export interface IBBCFlagDetails extends IFlags {
  clientArRawCurrent?: IArRawDataItem
  clientArRawPrevious?: IArRawDataItem
}

export interface IBBCFlagDetailsData {
  data?: IBBCFlagDetails[]
  totalItems?: number
}

export enum FlagTypes {
  BBC = 'BBC',
  Financials = 'Financials',
  GL = 'GL',
  OPS = 'Ops',
  Entity = 'Entity',
  Client = 'Client',
  BankTransactions = 'Bank Transactions',
}

export enum FlagStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum FlagLODs {
  Detail = 'Detail',
  Summary = 'Summary',
}

export enum FlagColors {
  Yellow = 'yellow',
  Red = 'red',
}

export const FLAG_TYPE_LABELS = {
  [FlagTypes.Client]: 'Client',
  [FlagTypes.BBC]: 'Borrowing Base',
  [FlagTypes.Financials]: 'Financials',
  [FlagTypes.GL]: 'General Ledger',
  [FlagTypes.OPS]: 'OPS',
  [FlagTypes.BankTransactions]: 'Bank Transactions',
}

export const BBC_FLAG_TYPES = {
  ar: {
    tablePrevious: 'clientArRawPrevious',
    tableCurrent: 'clientArRawCurrent',
    labelDbField: 'invoice',
    referenceDbField: 'memo',
    dateField: 'asOfDate',
  },
  inventory: {
    tablePrevious: 'clientInventoryPrevious',
    tableCurrent: 'clientInventoryCurrent',
    labelDbField: 'description',
    referenceDbField: 'sku',
    dateField: 'recordDate',
  },
}

export const DETAILED_BBC_FLAGS_MAPPING = {
  altered_date_flag: {
    field: 'invoiceDate',
    dbField: 'invoice_date',
    dateField: 'as_of_date',
    label: 'Invoice Date',
    type: 'date',
    sourceData: 'ar',
    showMessage: true,
  },
  altered_amount_flag: {
    field: 'balance',
    dbField: 'balance',
    dateField: 'as_of_date',
    label: 'Balance',
    type: 'amount',
    sourceData: 'ar',
  },
  duplicate_ar_invoices: {
    field: 'invoice',
    dbField: 'invoice',
    dateField: 'as_of_date',
    label: 'invoice',
    type: 'number',
    sourceData: 'ar',
  },
  reappearing_invoice: {
    field: 'balance',
    dbField: 'balance',
    dateField: 'as_of_date',
    label: 'Balance',
    type: 'number',
    sourceData: 'ar',
  },
  priority_invoice_paid: {
    field: 'invoice',
    dbField: 'invoice',
    dateField: 'as_of_date',
    label: 'invoice',
    type: 'number',
    sourceData: 'ap',
  },
  pending_cash_dominion: {
    field: 'description',
    dbField: 'description',
    dateField: 'recordDate',
    label: 'Description',
    type: 'string',
    sourceData: 'bankTransactions',
  },
  increased_unit_cost: {
    field: 'avgUnitCost',
    dbField: 'avg_unit_cost',
    dateField: 'record_date',
    label: 'Avg Unit Cost',
    type: 'amount',
    sourceData: 'inventory',
  },
  inventory_build: {
    field: 'onHand',
    dbField: 'on_hand',
    dateField: 'record_date',
    label: 'On Hand',
    type: 'number',
    sourceData: 'inventory',
  },
  low_current_receivables: {
    hash: '#ar-summary',
    type: 'ar',
  },
  increase_in_near_aged_ar: {
    hash: '#ar-summary',
    type: 'ar',
  },
  increase_in_ineligible: {
    hash: '#ineligible-ar',
    type: 'ar',
  },
  upcoming_3pl_reserves: {
    hash: '#ap-summary',
    type: 'ap',
  },
  potential_liquidity_issue_from_61_90: {
    hash: '#ap-summary',
    type: 'ap',
  },
  potential_liquidity_issue_from_91_plus: {
    hash: '#ap-summary',
    type: 'ap',
  },
  inventory_nearing_exclusion: {
    hash: '#inventory-charts',
    type: 'inventory',
  },
  increase_in_excluded: {
    hash: '#sku-performance',
    type: 'inventory',
  },
  projections_exceed_eligibility_rule: {
    hash: '#',
    type: 'inventory',
  },
  new_skus_flag: {
    hash: '#',
    type: 'inventory-ineligibility',
  },
}

export enum BBC_TABS {
  RECEIVABLES = 'Receivables',
  PAYABLES = 'Payables',
  INVENTORY = 'Inventory',
}
export enum BBC_AGING_REPORT {
  AR = 'ar',
  AP = 'ap',
}

export interface IARAPChartData {
  ar_1To_30Days: number
  ar_1To_30DaysPercent: number
  ar_31To_60Days: number
  ar_31To_60DaysPercent: number
  ar_61To_90Days: number
  ar_61To_90DaysPercent: number
  ar_91PlusDays: number
  ar_91PlusDaysPercent: number
  total: number
  recordDate: string
}

export interface IARAPChart {
  data: IARAPChartData[]
  entities: string[]
  id: string
}

export interface IARAPChartDropdownOptions {
  entities: string[]
  agingReport: BBC_AGING_REPORT
}

export enum BBC_MAPPING_TABS {
  Customer = 'Customer',
  Vendor = 'Vendor',
}

export const BBC_TAB_FILE_TYPE_MAPPING = {
  [BBC_TABS.RECEIVABLES]: OngoingReportingType.AR,
  [BBC_TABS.PAYABLES]: OngoingReportingType.AP,
  [BBC_TABS.INVENTORY]: OngoingReportingType.Inventory,
}

export enum ReportingFlow {
  OPS = 'OPS',
  DueDiligence = 'DueDiligence',
  OngoingReporting = 'ongoingReporting',
  ClientPage = 'client',
  ClientUserPage = 'clientUser',
  LoanCommitteeReport = 'loanCommitteeReport',
  BorrowingBase = 'borrowingBase',
}

export interface IInventoryLocation {
  id: string
  location: string
  mappedLocation?: string
  entityName?: string
  entity?: IEntityInfo
  warehouseWaiverLink?: string
}

export enum FinancialsType {
  Actuals = 'Actuals',
  Projections = 'Projections',
  StressCase = 'StressCase',
}

export const MOMENT_FORMAT_BY_PERIOD = {
  [ReportingPeriods.Monthly]: 'MMM YYYY',
  [ReportingPeriods.Quarterly]: '[Q]Q YYYY',
  [ReportingPeriods.Annually]: 'YYYY',
}

export const MOMENT_UNIT_OF_TIME_BY_REPORTING_PERIOD = {
  [ReportingPeriods.Monthly]: 'month',
  [ReportingPeriods.Quarterly]: 'quarter',
  [ReportingPeriods.Annually]: 'year',
}

export const MOMENT_UNIT_OF_TIME_BY_DISPLAY_GROWTH = {
  [DisplayGrowthPeriods.Month]: 'month',
  [DisplayGrowthPeriods.Quarter]: 'quarter',
  [DisplayGrowthPeriods.Year]: 'year',
}

export enum WorkflowPage {
  client = 'client',
  ongoingReporting = 'ongoingReporting',
  borrowingbase = 'borrowingBase',
  dueDilligence = 'dueDilligence',
  opsReporting = 'opsReporting',
}

export const NOT_MAPPED = 'Not Mapped'

export enum BBCReceivablesGroupBy {
  Customer = 'Customer',
  Parent = 'Parent',
}

export const BBC_RECEIVABLES_GROUP_BY_OPTIONS = [
  {
    value: BBCReceivablesGroupBy.Customer,
    label: 'Customer',
  },
  {
    value: BBCReceivablesGroupBy.Parent,
    label: 'Parent entity',
  },
]

export interface IDebtorIneligibleCategories {
  ineligibleCategories: string[]
}

export const INVENTORY_TYPE_ALIASES = {
  [SkuTypes.Finished]: [
    SkuTypes.Finished.toLowerCase(),
    'finished good',
    'fg',
    'fgi',
    'finished',
    'inventory - product',
    'fg - finished goods',
  ],
  [SkuTypes.InProcess]: [SkuTypes.InProcess.toLowerCase(), 'wip', 'work in progress'],
  [SkuTypes.Raw]: [
    SkuTypes.Raw.toLowerCase(),
    'raw material',
    'rm',
    'raw',
    'inventory - raw materials',
  ],
  [SkuTypes.Packaging]: [
    SkuTypes.Packaging.toLowerCase(),
    'pkg',
    'inventory - packaging inventory',
  ],
  [SkuTypes.Other]: [SkuTypes.Other.toLowerCase(), 'other'],
}
